import React from "react";

import { PageContainer } from "@byko/component-page-container";

import type { HtmlBlock as IHtmlBlock } from "@byko/lib-api-dato-client";
import { ContentSectionHtmlBlock } from "@byko/content-section-html-block";
import { Divider } from "../divider";
import styled from "styled-components";

export const IframeContainer = styled.div`
  height: 100%;
  iframe {
    overflow: hidden;
    height: 100%;
    border: none;
    outline: none;
  }
`;

interface ContentSectionWithIndex extends IHtmlBlock {
  index?: number;
}

export const ContentSectionHtml = (_content: ContentSectionWithIndex): JSX.Element => {
  if (_content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <ContentSectionHtmlBlock content={_content} />
    </PageContainer>
  );
};

export type ContentSectionBooking = IHtmlBlock;
