import React from "react";

import { MotionWrapper } from "@byko/lib-styles";

import { Content } from "./content";
import { BykoFrontPageContext } from "./context";
import { Divider } from "./divider";
import { Header } from "./header";

import type { BykoFrontPage as _BykoFrontPage } from "@byko/lib-api-dato-client";

interface Props {
  page: _BykoFrontPage;
}
export const BykoFrontPage = ({ page }: Props): JSX.Element => {
  return (
    <BykoFrontPageContext.Provider value={page}>
      <MotionWrapper>
        <Header />
        <Content />
        <Divider />
      </MotionWrapper>
    </BykoFrontPageContext.Provider>
  );
};
