import { createContext, useContext } from "react";

import type { BykoFrontPage } from "@byko/lib-api-dato-client";

export const BykoFrontPageContext = createContext<BykoFrontPage | null>(null);

export const useBykoFrontPageContext = (): BykoFrontPage => {
  const value = useContext(BykoFrontPageContext);
  if (value == null) {
    throw new Error("Incorrect usage of BykoFrontPageContext");
  }
  return value;
};
