import React, { useMemo } from "react";

import { ContentSectionEight } from "@byko/component-content-section-variant-eight";
import { PageContainer } from "@byko/component-page-container";
import { isNotNull } from "@byko/types-utils";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection8 as IContentSection8 } from "@byko/lib-api-dato-client";

interface ContentSectionWithIndex extends IContentSection8 {
  index?: number;
}

export const ContentSection8 = (content: ContentSectionWithIndex): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);
  const images = useMemo(() => {
    if (!content.images) {
      return {};
    }
    return content.images.filter(isNotNull).reduce((a, b, i) => {
      const key = i === 0 ? "smallImageOne" : i === 1 ? "smallImageTwo" : null;
      if (!key) {
        return a;
      }

      return {
        ...a,
        [key]: b.jpeg,
      };
    });
  }, [content.images]);

  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      {content.index !== 0 && <Divider />}
      <ContentSectionEight
        decorativeImageOne={content.primaryImage}
        mirrorX={content.mirrorY}
        {...paragraphProps}
        {...images}
      />
    </PageContainer>
  );
};

export type ContentSection8 = IContentSection8;
