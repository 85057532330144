import React, { useMemo } from "react";

import { ContentSectionFiles as ContentSectionFilesWrapper } from "@byko/component-content-section-files";
import { PageContainer } from "@byko/component-page-container";

import { getIcon } from "../icon";

import type { ContentSectionFiles as IContentSectionFiles } from "@byko/lib-api-dato-client";

interface ContentSectionWithIndex extends IContentSectionFiles {
  index?: number;
}

export const ContentSectionFiles = (content: ContentSectionWithIndex): JSX.Element => {
  const tabCollection = useMemo(() => {
    return content.fileBlock.map((e) => {
      const collection = e.files.map((file) => {
        return {
          id: file.id,
          key: file.filename,
          filePath: file.url,
        };
      });
      return {
        label: e.title ?? "",
        icon: e.icon ? getIcon({ icon: e.icon }) : null,
        content: <ContentSectionFilesWrapper.Item collection={collection} />,
      };
    });
  }, [content.fileBlock]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <ContentSectionFilesWrapper tabCollection={tabCollection} />
    </PageContainer>
  );
};

export type ContentSectionFiles = IContentSectionFiles;
