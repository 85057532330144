import Head from "next/head";
import React, { useMemo } from "react";

import { useBykoFrontPageContext } from "./context";
export const Header = (): JSX.Element => {
  const { seo } = useBykoFrontPageContext();
  const tags: Record<string, { content: string }> = useMemo(() => {
    const image = seo.image
      ? {
          "og:image": {
            content: seo.image.jpeg,
          },
          "og:image:type": {
            content: "image/jpeg",
          },
          "og:image:width": {
            content: seo.image.width.toString(),
          },
          "og:image:height": {
            content: seo.image.height.toString(),
          },
          "og:image:alt": {
            content: seo.image.alt ?? "",
          },
        }
      : {};
    return {
      "og:type": {
        content: "website",
      },
      "og:description": {
        content: seo.description,
      },
      "og:title": {
        content: seo.title,
      },
      "twitter:site": {
        content: "@bykoehf",
      },
      ...image,
    };
  }, [seo.description, seo.title, seo.image]);
  const title = seo.title === "BYKO" ? seo.title : `BYKO - ${seo.title}`;
  return (
    <Head>
      <title>{title}</title>
      <meta key="description" content={seo.description} name="description" />
      {Object.keys(tags).map((e) => {
        return <meta key={e} content={tags[e]?.content} property={e} />;
      })}
    </Head>
  );
};
