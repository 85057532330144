/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react/no-danger */
import React from "react";

import { PageContainer } from "@byko/component-page-container";

import type { KlaviyoBlock as IKlaviyoBlock } from "@byko/lib-api-dato-client";
import { Divider } from "../divider";

interface ContentSectionWithIndex extends IKlaviyoBlock {
  index?: number;
}

export const ContentKlaviyoEmbed = (_content: ContentSectionWithIndex): JSX.Element => {
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <div className="klaviyo-embed-container" dangerouslySetInnerHTML={{ __html: _content.embedCode }} />
    </PageContainer>
  );
};

export type ContentSectionBooking = IKlaviyoBlock;
