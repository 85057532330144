import React, { useMemo } from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantThree } from "@byko/content-section-variant-three";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection3 as IContentSection3 } from "@byko/lib-api-dato-client";

interface ContentSectionWithIndex extends IContentSection3 {
  index?: number;
}

export const ContentSection3 = (content: ContentSectionWithIndex): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);
  const { decorativeImageOne, decorativeImageTwo, decorativeImageThree } = useMemo(() => {
    if (!content.images) {
      return {
        decorativeImageOne: null,
        decorativeImageTwo: null,
        decorativeImageThree: null,
      };
    }
    return {
      decorativeImageOne: content.images[0] ?? null,
      decorativeImageTwo: content.images[1] ?? null,
      decorativeImageThree: content.images[2] ?? null,
    };
  }, [content.images]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      {content.index !== 0 && <Divider />}
      <ContentSectionVariantThree
        decorativeImageOne={decorativeImageOne}
        decorativeImageThree={decorativeImageThree}
        decorativeImageTwo={decorativeImageTwo}
        mirrorY={content.mirrorY}
        {...paragraphProps}
      />
    </PageContainer>
  );
};

export type ContentSection3 = IContentSection3;
