import React, { useMemo } from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantFour } from "@byko/content-section-variant-four";

import { Divider } from "../divider";

import { useBulletValue } from "./use-bullet";

import type { DatoImageWithTitle, ContentSection4 as IContentSection4 } from "@byko/lib-api-dato-client";

interface ContentSectionWithIndex extends IContentSection4 {
  index?: number;
}

export const ContentSection4 = (content: ContentSectionWithIndex): JSX.Element => {
  const { ...paragraphProps } = useBulletValue(content.bulletBlockContent);

  const { decorativeImageOne, decorativeImageTwo } = useMemo(() => {
    return {
      decorativeImageOne: content.primaryImages[0] as DatoImageWithTitle,
      decorativeImageTwo: content.primaryImages[1] as DatoImageWithTitle,
    };
  }, [content.primaryImages]);
  const tagImages = useMemo(() => content.images ?? [], [content.images]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      {content.index !== 0 && <Divider />}
      <ContentSectionVariantFour
        decorativeImageOne={decorativeImageOne}
        decorativeImageTwo={decorativeImageTwo}
        mirrorY={content.mirrorY}
        tagImageOne={tagImages[0]}
        tagImageTwo={tagImages[1]}
        {...paragraphProps}
      />
    </PageContainer>
  );
};

export type ContentSection4 = IContentSection4;
