import React, { useMemo } from "react";

import { ContentSectionImageTiles } from "@byko/component-content-section-image-tiles";
import { PageContainer } from "@byko/component-page-container";

import { Divider } from "../divider";

import type { ImageTileItem } from "@byko/component-content-section-image-tiles";
import type { ImageTilesBlockSection as IImageTilesBlockSection } from "@byko/lib-api-dato-client";

interface ContentSectionWithIndex extends IImageTilesBlockSection {
  index?: number;
}

export const ContentSectionImageTilesBlock = (content: ContentSectionWithIndex): JSX.Element => {
  const tiles = useMemo((): ImageTileItem[] => {
    const images: ImageTileItem[] = [];
    content.images.forEach((image, index) => {
      images.push({
        alt: image.alt,
        id: index,
        image: image.jpeg,
        size: "medium",
      });
    });
    return images;
  }, [content.images]);

  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      {content.index !== 0 && <Divider />}
      <ContentSectionImageTiles size="medium" tiles={tiles} />
    </PageContainer>
  );
};
export type ImageTilesBlockSection = IImageTilesBlockSection;
