import React from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantSix } from "@byko/content-section-variant-six";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection6 as IContentSection6 } from "@byko/lib-api-dato-client";
import { Container } from "../styles";

interface ContentSectionWithIndex extends IContentSection6 {
  index?: number;
}

export const ContentSection6 = (content: ContentSectionWithIndex): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      {content.index !== 0 && (
        <>
          <Divider />
          <Container>
            <ContentSectionVariantSix mirrorX={content.mirrorX} primaryImage={content.image} {...paragraphProps} />
          </Container>
        </>
      )}
      {content.index === 0 && (
        <ContentSectionVariantSix
          isTopContent={true}
          mirrorX={content.mirrorX}
          primaryImage={content.image}
          {...paragraphProps}
        />
      )}
    </PageContainer>
  );
};

export type ContentSection6 = IContentSection6;
