import React from "react";

import { MotionWrapper } from "@byko/lib-styles";

import { useBykoFrontPageContext } from "../context";

import { ContentSectionBooking } from "./content-section-booking";
import { ContentSectionImageTilesBlock } from "./content-section-image-tiles";
import { ContentSectionPhotoGalleryBlock } from "./content-section-photo-gallery";
import { ContentSection1 } from "./content-section1";
import { ContentSection2 } from "./content-section2";
import { ContentSection3 } from "./content-section3";
import { ContentSection4 } from "./content-section4";
import { ContentSection5 } from "./content-section5";
import { ContentSection6 } from "./content-section6";
import { ContentSection7 } from "./content-section7";
import { ContentSection8 } from "./content-section8";
import { ContentSection9 } from "./content-section9";
import { ContentSectionFiles } from "./content-sectionfiles";
import { ProductSection } from "./product-section";
import { ContentKlaviyoEmbed } from "./content-klaviyo-embed";

import type { ContentSection } from "@byko/lib-api-dato-client";
import { ContentSectionHtml } from "./content-section-html";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContentSectionParse: Record<ContentSection["type"], (value: any) => JSX.Element> = {
  ContentSection1: ContentSection1,
  ContentSection2: ContentSection2,
  ContentSection3: ContentSection3,
  ContentSection4: ContentSection4,
  ContentSection5: ContentSection5,
  ContentSection6: ContentSection6,
  ContentSection7: ContentSection7,
  ContentSection8: ContentSection8,
  ContentSection9: ContentSection9,
  ContentSectionFiles: ContentSectionFiles,
  ContentSectionProduct: ProductSection,
  ImageTilesBlock: ContentSectionImageTilesBlock,
  PhotoGalleryBlock: ContentSectionPhotoGalleryBlock,
  SimplybookmeBlock: ContentSectionBooking,
  HtmlBlock: ContentSectionHtml,
  KlaviyoFormEmbed: ContentKlaviyoEmbed,
};

export const Content = (): JSX.Element => {
  const { content } = useBykoFrontPageContext();

  const unhiddenContent = content.filter((value) => {
    if (value.type === "KlaviyoFormEmbed") {
      return true;
    }
    return !value.hidden;
  });

  return (
    <div>
      {unhiddenContent.map((value, index) => {
        const Component = ContentSectionParse[value.type];
        if (!Component) {
          return null;
        }
        if (value.type === "PhotoGalleryBlock") {
          return <Component key={value.id} {...value} />;
        }

        return (
          <MotionWrapper key={value.id}>
            <Component {...value} index={index} />
          </MotionWrapper>
        );
      })}
    </div>
  );
};
