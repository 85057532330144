import React from "react";

import { BookingSection } from "@byko/component-booking-section";
import { PageContainer } from "@byko/component-page-container";

import { Divider } from "../divider";

import type { ContentSectionBooking as IContentSectionBooking } from "@byko/lib-api-dato-client";

interface ContentSectionWithIndex extends IContentSectionBooking {
  index?: number;
}

// eslint-disable-next-line unused-imports/no-unused-vars
export const ContentSectionBooking = (_content: ContentSectionWithIndex): JSX.Element => {
  if (_content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <BookingSection />
    </PageContainer>
  );
};

export type ContentSectionBooking = IContentSectionBooking;
